<template>
    <div class="m2 wrapper wrapper--default">
        <h3 class="text-center m-2">Merken</h3>
        <hr>
        <Loading v-if="loading" />
        <div class="p-2" v-else>
            <div class="flex center">
                <PurchaseDuoTable :table_data="brands" :bu="bu" />
            </div>
        </div>
    </div>
</template>
<script>
import PurchaseDuoTable from '@/components/PurchaseDuoComponents/PurchaseDuoTable.vue';
import Loading from '@/components/Loading.vue';
import request from '@/functions/request.js';

export default {
    props: ['bu', 'kind', 'structure'],
    components: { PurchaseDuoTable, Loading },
    data: () => ({
        brands: null,
        loading: true
    }),
    created() {
        if(this.structure == ''){
            this.structure = 'Overig';
        }
        this.getData(this.bu, this.kind, this.structure)
    },
    watch: {
        bu(newbu) {
            this.loading = true;
            this.getData(newbu, this.kind, this.structure)
        }
    },
    methods: {
        getData(bu, kind, structure) {
            request(`purchaseduo/brand/${bu}/${kind}/${structure}`, "GET").then(({ brands }) => {
                this.brands = brands;
                this.loading = false;
            })
        }
    }
};
</script>